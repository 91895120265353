import { Typography } from '@mui/material';
import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';

interface SubInfoProp extends TwContainer {}

const SubInfo = ({ children, containerClasses, typographyStyles, ...props }: SubInfoProp) => {
  return (
    <div className={classNames(containerClasses)} {...props}>
      <Typography variant="subinformation" style={{ fontFamily: 'ABeeZee, sans-serif', ...typographyStyles }}>
        {children}
      </Typography>
    </div>
  );
};

export default SubInfo;