import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';

interface SquareButtonProps extends TwContainer {
  muiIconName?: string;
}

const SquareButton = ({ containerClasses, children, onClick }: SquareButtonProps) => {
  return (
    <div
      className={classNames(
        containerClasses,
        'bg-[#263238] flex justify-center items-center w-6 h-6 sm:w-10 sm:h-10 p-2 rounded-md sm:rounded-lg cursor-pointer',
      )}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default SquareButton;
