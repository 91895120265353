import React from 'react'
import ClientsSection from '../views/landing/ClientsSection'
import ProjectsSection from '../views/landing/ProjectsSection'

interface Props { }

const Projects = (props: Props) => {
    const { } = props

    return (
        <div style = {{marginTop:'12rem'}}>
            <ProjectsSection />
            <div className="mb-10">
                <ClientsSection />
            </div>
        </div>
    )
}

export default Projects