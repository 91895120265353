export const expertiseArray = [
    {
        name: 'React/React Native',
        icon: 'react_native_logo.svg',
    },
    {
        name: 'Angular',
        icon: 'angular_logo.svg',
    },
    {
        name: 'TypeScript',
        icon: 'typescript_logo.png',
    },
    {
        name: 'Python',
        icon: 'python_logo.svg',
    },
    {
        name: 'Next.js',
        icon: 'nextjs_logo.svg',
    },
    {
        name: 'GraphQL',
        icon: 'graphql_logo.svg',
    },
    {
        name: 'HTML',
        icon: 'html_logo.svg',
    },
    {
        name: 'CSS',
        icon: 'css_logo.svg',
    },
    {
        name: 'Tailwind CSS',
        icon: 'tailwind_css_logo.svg',
    },
    {
        name: 'Material UI',
        icon: 'material_ui_logo.svg',
    },
    {
        name: 'Jest',
        icon: 'jest_logo.png',
    },
    {
        name: 'MySQL',
        icon: 'mysql_logo.png',
    },
    {
        name: 'NestJS',
        icon: 'nestjs_logo.svg',
    },
    {
        name: 'Vue.js',
        icon: 'vuejs_logo.svg',
    },
    {
        name: 'Node.js',
        icon: 'nodejs_logo.svg',
    },
    {
        name: 'MongoDB',
        icon: 'mongodb_logo.svg',
    },
    {
        name: 'PostgreSQL',
        icon: 'postgresql_logo.svg',
    },
    {
        name: 'Redux',
        icon: 'redux_logo.png',
    },
    {
        name: 'Amazon Web Services',
        icon: 'aws_logo.svg',
    },
    {
        name: 'Firebase',
        icon: 'firebase_logo.jpg',
    },
    {
        name: 'Express.js',
        icon: 'express_logo.svg',
    },
    {
        name: 'Figma',
        icon: 'figma_logo.svg',
    },
    {
        name: 'Adobe XD',
        icon: 'adobexd_logo.svg',
    },
    {
        name: 'TypeORM',
        icon: 'typeorm_logo.png',
    },
    {
        name: 'GitHub',
        icon: 'github_logo.svg',
    },
];

export const webExpertiseArray = [
    {
        name: 'React',
        icon: 'react_native_logo.svg',
    },
    {
        name: 'Angular',
        icon: 'angular_logo.svg',
    },
    {
        name: 'TypeScript',
        icon: 'typescript_logo.png',
    },
    {
        name: 'Python',
        icon: 'python_logo.svg',
    },
    {
        name: 'Next.js',
        icon: 'nextjs_logo.svg',
    },
    {
        name: 'GraphQL',
        icon: 'graphql_logo.svg',
    },
    {
        name: 'HTML',
        icon: 'html_logo.svg',
    },
    {
        name: 'CSS',
        icon: 'css_logo.svg',
    },
    {
        name: 'Tailwind CSS',
        icon: 'tailwind_css_logo.svg',
    },
    {
        name: 'Material UI',
        icon: 'material_ui_logo.svg',
    },
    {
        name: 'Jest',
        icon: 'jest_logo.png',
    },
    {
        name: 'MySQL',
        icon: 'mysql_logo.png',
    },
    {
        name: 'NestJS',
        icon: 'nestjs_logo.svg',
    },
    {
        name: 'Vue.js',
        icon: 'vuejs_logo.svg',
    },
    {
        name: 'Node.js',
        icon: 'nodejs_logo.svg',
    },
    {
        name: 'MongoDB',
        icon: 'mongodb_logo.svg',
    },
    {
        name: 'PostgreSQL',
        icon: 'postgresql_logo.svg',
    },
    {
        name: 'Redux',
        icon: 'redux_logo.png',
    },
    {
        name: 'Amazon Web Services',
        icon: 'aws_logo.svg',
    },
    {
        name: 'Firebase',
        icon: 'firebase_logo.jpg',
    },
    {
        name: 'Express.js',
        icon: 'express_logo.svg',
    },
    {
        name: 'Figma',
        icon: 'figma_logo.svg',
    },
    {
        name: 'Adobe XD',
        icon: 'adobexd_logo.svg',
    },
    {
        name: 'TypeORM',
        icon: 'typeorm_logo.png',
    },
    {
        name: 'GitHub',
        icon: 'github_logo.svg',
    },
];

export const mobileExpertiseArray = [
    {
        name: 'React Native',
        icon: 'react_native_logo.svg',
    },
    {
        name: 'TypeScript',
        icon: 'typescript_logo.png',
    },
    {
        name: 'Python',
        icon: 'python_logo.svg',
    },
    {
        name: 'GraphQL',
        icon: 'graphql_logo.svg',
    },
    {
        name: 'CSS',
        icon: 'css_logo.svg',
    },
    {
        name: 'Tailwind CSS',
        icon: 'tailwind_css_logo.svg',
    },
    {
        name: 'Jest',
        icon: 'jest_logo.png',
    },
    {
        name: 'MySQL',
        icon: 'mysql_logo.png',
    },
    {
        name: 'NestJS',
        icon: 'nestjs_logo.svg',
    },
    {
        name: 'Node.js',
        icon: 'nodejs_logo.svg',
    },
    {
        name: 'MongoDB',
        icon: 'mongodb_logo.svg',
    },
    {
        name: 'PostgreSQL',
        icon: 'postgresql_logo.svg',
    },
    {
        name: 'Redux',
        icon: 'redux_logo.png',
    },
    {
        name: 'Amazon Web Services',
        icon: 'aws_logo.svg',
    },
    {
        name: 'Firebase',
        icon: 'firebase_logo.jpg',
    },
    {
        name: 'Express.js',
        icon: 'express_logo.svg',
    },
    {
        name: 'Figma',
        icon: 'figma_logo.svg',
    },
    {
        name: 'Adobe XD',
        icon: 'adobexd_logo.svg',
    },
    {
        name: 'TypeORM',
        icon: 'typeorm_logo.png',
    },
    {
        name: 'GitHub',
        icon: 'github_logo.svg',
    },
];