import React from 'react'
// import SoftwareItem from '../../../components/common/SoftwareItem'
import SoftwareItem from '../../../components/common/SoftwareItem'

interface Props {
    items: any
}

function SoftwareList(props: Props) {
    const { items } = props

    return (
        <div className='grid grid-cols-2 gap-5 breaktwo: content-center ' >
            {items.map((item: any) => (
                <SoftwareItem label={item} />

            ))}
        </div>

    )
}

export default SoftwareList