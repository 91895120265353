import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import SubInfo from '../../components/common/SubInfo';
import BigCard from '../../components/layout/BigCard';
import { imageUrl } from '../../utils';
import Carousel from './../../components/common/Carousel';
import { ClassNames } from '@emotion/react';

const projectsArray = [
  {
    name: 'Everyday Inclusion CMS',
    description: 'Content management system for the Everyday Inclusion Mobile App',
    type: 'Web',
    tech: 'React | NodeJS | PostgreSQL | AWS',
    icon: 'everyday_inclusion_cms.jpg',
  },
  {
    name: 'Popl',
    description: 'Teams digital business card',
    type: 'Web/Mobile',
    tech: 'React | Firebase | API Integrations',
    icon: 'popl_teams.png',
  },
  {
    name: 'Apexcare',
    description: 'Senior home care management system',
    type: 'Web',
    tech: 'React | NodeJS | MongoDB | AWS',
    icon: 'apexcare.png',
  },
  {
    name: 'Everyday Inclusion App',
    description: 'Business focused app built to deliver the best inclusion information',
    type: 'Mobile',
    tech: 'React Native | Node.js | PostgreSQL | AWS | Firebase',
    icon: 'everyday_inclusion_app.png',
  },
  // {
  //   name: 'RethinkHOA',
  //   description: 'HOA management system',
  //   type: 'Web',
  //   tech: 'React | Node.js | PostgreSQL | AWS | Firebase | Stripe API',
  //   icon: 'project_rivo_agency.svg',
  // },
  {
    name: 'Tour Golf League',
    description: 'Golf lessons booking system',
    type: 'Web',
    tech: 'React | Node.js | PostgreSQL | Firebase | Stripe API',
    icon: 'tour_golf_league.jpg',
  },
  {
    name: 'Quaquiz',
    description: 'Mobile app for interactive daily quiz play and social platform',
    type: 'Mobile',
    tech: 'React Native',
    icon: 'quaquiz.png',
  },
  {
    name: 'Everyday Inclusion Dashboard',
    description: 'Dashboard for company users from the Everyday Inclusion Mobile App',
    type: 'Web',
    tech: 'React | NodeJS | PostgreSQL | AWS',
    icon: 'everyday_inclusion_dashboard.png',
  },
  {
    name: 'Lead AutoPilot',
    description: 'Automates finding clients and filling the sales pipeline',
    type: 'Web',
    tech: 'Angular | Firebase | HighLevel API | Google Sheet API',
    icon: 'lead_autopilot.jpg',
  },
  {
    name: 'Cornerstone Billing',
    description: 'Account management system for security alarm dealers',
    type: 'Mobile',
    tech: 'Angular | Ionic',
    icon: 'cornerstone_billing.png',
  },
  {
    name: 'Memorabl',
    description: 'Private place to share for every occasion',
    type: 'Web',
    tech: 'React | AWS',
    icon: 'memorabl.png',
  },
  {
    name: 'Ares',
    description: 'Inventory tracking system',
    type: 'Web',
    tech: 'React | Firebase | Node.js | PostgreSQL',
    icon: 'ares.jpg',
  },
  // {
  //   name: 'Whatboard',
  //   description: 'Real-time collaboration and sharing all in one dashboard',
  //   type: 'Web',
  //   tech: 'React | Firebase',
  //   icon: 'project_travel_world.svg',
  // },
  {
    name: 'Upkeep',
    description: 'Platform to find, book, and pay for all your favorite MedSpa services',
    type: 'Web/Mobile',
    tech: 'React | Node.js | TypeORM | Postgres | Docker | Firebase',
    icon: 'upkeep.png',
  },
  // {
  //   name: 'Boost',
  //   description: 'Finance management for side hustle',
  //   type: 'Mobile',
  //   tech: 'React Native',
  //   icon: 'project_travel_world.svg',
  // },
];

const ProjectsSection = () => {
  const { t } = useTranslation();
  const isLessThan920 = useMediaQuery('(max-width: 920px)');
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan1024 = useMediaQuery('(max-width: 1024px)');

  const TopLeftElement = (
    <div>
      <RoundInfo containerClasses="mb-18 mt-20">{t('completed_projects')}</RoundInfo>
      <Info
        typographyStyles={{
          fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42
        }}>
        {t('projects_delivered_desc')}
      </Info>
    </div>
  );
  const TopRightElement = (
    <div className="breaktwo:text-center">
      <div className="flex justify-end items-center breaktwo:justify-center">
        <Info
          typographyStyles={{
            fontSize: isLessThan920 ? (isLessThan640 ? 70 : 80 ) : 150,
            fontFamily: 'ABeeZee',
            color: '#263238',
            fontStyle: 'italic',
          }}>
          <p>
            70<sup>+</sup>
          </p>
        </Info>
      </div>
      <SubInfo typographyStyles={{ color: '#263238' }} containerClasses="mr-4">
        {t('projects_delivered')}
      </SubInfo>
    </div>
  );
  const BottomElement = (
    <>
      {/* <div className="cursor-pointer swiper-go-left-projects mr-8 mt-[10%] breaktwo:mt-[15%]"> */}
      <div className="cursor-pointer swiper-go-left-projects mr-8 mt-[30%] sm:mt-[15%] breaktwomin:mt-[10%]">
        <img className="w-72 sm:w-52 pl-2" src={imageUrl('arrow_left.svg')} alt=""></img>
      </div>
      <Carousel
        dataArray={projectsArray}
        rowsPerSlide={1}
        slidesPerView={isLessThan640 ? 1 : isLessThan1024 ? 2 : 3}
        leftBtnClassName={'swiper-go-left-projects'}
        rightBtnClassName={'swiper-go-right-projects'}
        calledFrom="projects"
        pagination
        spaceBetween={40}
        loop
        autoplay
      />
      {/* <div className={`cursor-pointer swiper-go-right-projects ml-8 mt-[10%] breaktwo:mt-[15%]`}> */}
      <div className={`cursor-pointer swiper-go-right-projects ml-8 mt-[30%] sm:mt-[15%] breaktwomin:mt-[10%]`}>
        <img className="w-72 sm:w-52 pr-2" src={imageUrl('arrow_right.svg')} alt="" />
      </div>
    </>
  );

  return (
    <BigCard
      roundInfoText="Completed Projects"
      mainInfoText="Projects that we have delivered to our clients"
      topLeftElement={TopLeftElement}
      topRightElement={TopRightElement}
      bottomElement={BottomElement}
      containerClasses="bg-orange mt-10 mb-16 breaktwo:mt-8 breaktwo:mb-10"
      topRightContainerClasses="text-end"
      bottomContainerClasses="w-[82.5%] breaktwo:w-full pt-8 pb-5 flex breaktwo:pt-0"
      id="projects"
    />
  );
};

export default ProjectsSection;
