import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import OurExpertise from '../views/landing/OurExpertise'
import ProjectsSection from '../views/landing/ProjectsSection'
import ServiceList from '../views/dev/ServiceList'
import { web_dev_service_items } from '../constants/servicesItems'
import { expertiseArray } from '../constants/Expertise'
import classNames from 'classnames'
import { useMediaQuery } from '@mui/material'

const WebDevServices = () => {
    const isLessThan1331 = useMediaQuery('(max-width: 1331px)');

    return (
        <>
            <Header
                pillLabel='web_dev_pill'
                title='web_dev_motto'
                description='web_app_dev_description'
                renderButton
                buttonText='get_instant_quote'
                image='web_dev_header.svg'
            />
            <Banner
                pillLabel='custom_web_app_pill'
                title='web_dev_title'
                description='web_app_description'
            />
            <ServiceList 
                items={web_dev_service_items}
            />
            <div className={classNames('breaktwo:mb-12 mb-20')}>
               <OurExpertise 
                title = 'our_expertise'
                description = 'most_up_to_date_tech'
                displayItems={expertiseArray}
            />
            </div>
            <ProjectsSection />
        </>
    )
}
export default WebDevServices