import { Divider, Typography } from '@mui/material';
import { RoundedArrowButton } from '../buttons';
import GapAround from '../hoc/GapAround';
import classNames from 'classnames';
import { imageUrl } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Cross as Hamburger } from 'hamburger-react';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import NavbarDropDown from './NavbarDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const linksMap: any = {
  Home: '/',
  About: '/about',
  Projects: '/projects', 
  'Contact Us': '/contact-us'
}

const servicesPaths = ['/web', '/mobile', '/ui-ux', '/consultation'];

const coloredLogo = imageUrl('atini-logo-colored.svg');
const navbarMotion = {
  rest: { ease: 'easeOut', duration: 0.2, type: 'tween', x: '100vw' },
  drawer: {
    x: 0,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};
const serviceCategories = [
  { label: 'Web App Development', path: '/web' },
  { label: 'Mobile App Development', path: '/mobile' },
  { label: 'UI/UX', path: '/ui-ux' },
  { label: 'Software Consultation', path: '/consultation' }
]


const Navbar = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const commonNavlinkClasses = 'justify-center hover:underline underline-offset-8 decoration-yellow decoration-2 text-center';
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const TwLink = ({ text }: { text: string }) => {
    return (
      <div
        className={classNames('cursor-pointer py-2 hover:bg-yellow', pathname === linksMap[text] && 'bg-yellow')}
        onClick={() => {
          setDrawerOpen(false);
        }}>
        <Typography variant="button">{text}</Typography>
      </div>
    );
  };

  useEffect(() => {
    let handleResize = (e: any) => {
      setDrawerOpen(false);
      setServicesOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      setDrawerOpen(false);
      setServicesOpen(false);

    };
  }, []);

  const handleNavlinkClick = (path: string) => {
    navigate(path)
    setDrawerOpen(false);
    setServicesOpen(false);
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-10 w-screen bg-white">
      <div className="flex items-center h-[7rem] md:h-navbarHeight justify-center mdmax:justify-between px-1 sm:px-3  relative">
        <div className="flex justify-center items-center">
          <div className="hidden mdmax:flex">
            <Hamburger toggled={drawerOpen} onToggle={setDrawerOpen} size={22} />
          </div>
          {/* this is for logo image */}
          <Link to={'/'}>
            <div className="cursor-pointer">
              <img className={classNames('h-[calc(theme(space.navbarHeight)-50px)]')} src={coloredLogo} alt="logo" />
            </div>
          </Link>
        </div>
        {/* this is for logo image end */}

        {/* this is for the nav links present */}
        <div className="flex mx-16 space-x-6 breaktwo:space-x-10 breaktwomin:space-x-8 lg:space-x-12 breakonemin:space-x-20 mdmax:hidden">
          <Link to="/">
            <div className={classNames('cursor-pointer', commonNavlinkClasses, pathname === '/' && 'underline')}>
              <Typography variant="button">Home</Typography>
            </div>
          </Link>
          <Link to="/about">
            <div className={classNames('cursor-pointer', commonNavlinkClasses, pathname === '/about' && 'underline')}>
              <Typography variant="button">About</Typography>
            </div>
          </Link>
          <NavbarDropDown
            label={
              <div className={classNames('cursor-pointer', commonNavlinkClasses, ['/web', '/mobile', '/ui-ux', '/consultation'].includes(pathname) && 'underline')}>
                <Typography variant="button">Services<ExpandMoreIcon sx={{ width: 18, marginLeft: 0.5 }} /></Typography>
              </div>}
            subLabels={serviceCategories}
          />
          <Link to="/projects">
            <div className={classNames('cursor-pointer', commonNavlinkClasses, pathname === '/projects' && 'underline')}>
              <Typography variant="button">Projects</Typography>
            </div>
          </Link>
          {/* TODO: Put back blog */}
          {/* <Link to="/blogs">
              <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                <Typography variant="button">Blogs</Typography>
              </div>
            </Link> */}
        </div>
        <RoundedArrowButton
          text={t('contact_us')}
          arrowContainerClasses="ml-10"
          containerClasses="mdmax:hidden"
          onClick={() => navigate('/contact-us')}
        />
        <div className="hidden lgmax:flex lgmax:items-center lgmax:gap-2">
          <div className="md:hidden">
            <RoundedArrowButton text={t('contact_us')} containerClasses="mr-2" arrowContainerClasses="ml-10" onClick={() => navigate('/contact-us')}></RoundedArrowButton>
          </div>
          <AnimatePresence>
            {drawerOpen && (
              <motion.div
                className="flex flex-col text-center absolute left-0 right-0 top-28 min-w-[300px] bg-gray-100 py-4 gap-2 rounded-lg mx-10"
                variants={navbarMotion}
                initial={{ x: -500 }}
                animate="drawer"
                exit="rest">
                <>
                  <Link
                    to="/"
                    onClick={() => handleNavlinkClick('/')}
                  >
                    <TwLink text={t('home')} />
                  </Link>
                  <Link
                    to="/about"
                    onClick={() => handleNavlinkClick('/about')}

                  >
                    <TwLink text={t('about')} />
                  </Link>
                  <div
                    className={classNames('cursor-pointer py-2 hover:bg-yellow', servicesPaths.includes(pathname) && 'bg-yellow')}
                    onClick={() => setServicesOpen(prev => !prev)}
                  >
                    <Typography sx={{ position: 'relative' }} variant="button">{t('services')}<ExpandMoreIcon sx={{ width: 14, position: 'absolute', top: -1, right: -16 }} /></Typography>
                  </div>
                  {servicesOpen && (
                    <>
                      <Divider />
                      <div className="flex flex-col mx-4">
                        {serviceCategories.map((service: any) => (
                          <Link key={service.path} to={service.path} onClick={() => handleNavlinkClick(service.path)}>
                            <div
                              className="cursor-pointer py-2 hover:bg-yellow"
                              onClick={() => {
                                setDrawerOpen(false);
                              }}>
                              <Typography fontSize={16} variant="button">  {t(service.label)}</Typography>
                            </div>
                          </Link>
                        ))}
                      </div>
                      <Divider />
                    </>
                  )}
                  {/* TODO: Put back blog */}
                  {/* <Link
                      to="/blogs"
                    >
                      <TwLink>
                        <Typography variant="button">{t('blog')}</Typography>
                      </TwLink>
                    </Link> */}
                  <Link
                    to="/projects"
                    onClick={() => handleNavlinkClick('/projects')}
                  >
                    <TwLink text={t('projects')} />
                  </Link>
                  <Link
                    to="/contact-us"
                  >
                    <TwLink text={t('contact_us')} />
                  </Link>
                </>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Navbar;