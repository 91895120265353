import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import DevServices from '../views/services/mobileAppDev/DevServices'
import OurExpertise from '../views/landing/OurExpertise'
import ProjectsSection from '../views/landing/ProjectsSection'
import { mobileExpertiseArray } from '../constants/Expertise'
import { useMediaQueries } from '../contexts/media-query'
import classNames from 'classnames'

interface Props { }

const MobileAppDev = (props: Props) => {
    const { } = props
    return (
        <>
            <Header
                pillLabel='mobile_app_dev'
                title='mobile_app_dev_motto'
                description='mobile_app_dev_description'
                renderButton
                buttonText='get_instant_quote'
                image='mobile_app_dev.svg'
            />
            <Banner
                pillLabel={'our_expertise'}
                title={'expertise_motto'}
                description={'expertise_description'}
            />
            <DevServices />
            <div className={classNames('breaktwo:mb-12 mb-20')}>
                <OurExpertise 
                    title = 'tech_we_use'
                    description = 'tech_we_use_description'
                    displayItems={mobileExpertiseArray}
                />
            </div>
            <ProjectsSection />
        </>
    )
}

export default MobileAppDev