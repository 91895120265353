import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';

interface WithNavbarHeightAppliedProps extends TwContainer {
  fullHeight?: boolean;
}

const WithNavbarHeightApplied = ({
  children,
  containerClasses,
  childrenClasses,
  fullHeight,
}: WithNavbarHeightAppliedProps) => {
  return (
    <div
      className={classNames(
        {
          // 'h-[calc(100vh-theme(space.navbarHeight))]': fullHeight,
          // 'min-h-[calc(100vh-theme(space.navbarHeight))]': !fullHeight,
        },
        containerClasses,
      )}>
      <div className={classNames(childrenClasses, 'h-full')}>{children}</div>
    </div>
  );
};

export default WithNavbarHeightApplied;
