import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './pages/LandingPage';
import WithNavbarAndFooter from './components/hoc/WithNavbarAndFooter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { theme } from './constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EnglishTranslation from './i18n/en.json';
import ChineseTranslation from './i18n/zh.json';
import { Fragment } from 'react';
import classNames from 'classnames';
import Blog from './pages/Blog';
import BlogArticle from './views/blogs/BlogArticle';
import About from './pages/About';
import Projects from './pages/Projects';
import ContactUsPage from './pages/ContactUsPage';
import MobileAppDev from './pages/MobileAppDev';
import WebDevServices from './pages/WebDevServices';
import Ux from './pages/Ux';
import SoftwareConsulting from './pages/SoftwareConsulting';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'zh'],
    resources: {
      en: {
        translation: EnglishTranslation,
      },
      zh: {
        translation: ChineseTranslation,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['path', 'querystring', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

function App() {
  return (
    <div style={{ position: 'relative' }}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className={classNames('relative')}>
            <Fragment>
              <Routes>
                <Route
                  path="/"
                  element={
                    <WithNavbarAndFooter>
                      <LandingPage />
                    </WithNavbarAndFooter>
                  }
                />
                {/* TODO: Put back blogs */}
                {/* <Route
                  path="/blogs"
                  element={
                    <WithNavbarAndFooter>
                      <Blog />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/blogs/:slug"
                  element={
                    <WithNavbarAndFooter>
                      <BlogArticle />
                    </WithNavbarAndFooter>
                  }
                /> */}
                <Route
                  path="/about"
                  element={
                    <WithNavbarAndFooter>
                      <About />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/projects"
                  element={
                    <WithNavbarAndFooter>
                      <Projects />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/contact-us"
                  element={
                    <WithNavbarAndFooter>
                      <ContactUsPage />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/mobile"
                  element={
                    <WithNavbarAndFooter>
                      <MobileAppDev />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/web"
                  element={
                    <WithNavbarAndFooter>
                      <WebDevServices />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/ui-ux"
                  element={
                    <WithNavbarAndFooter>
                      <Ux />
                    </WithNavbarAndFooter>
                  }
                />
                <Route
                  path="/consultation"
                  element={
                    <WithNavbarAndFooter>
                      <SoftwareConsulting />
                    </WithNavbarAndFooter>
                  }
                />
                {/* <Route
                  path="instant-quote"
                  element={
                    <WithNavbarAndFooter>
                      <InstantQuote />
                    </WithNavbarAndFooter>
                  }
                /> */}
                {/* <Route
              path="our-team"
              element={
                <WithNavbarAndFooter>
                  <OurTeamPage />
                </WithNavbarAndFooter>
              }
            /> */}
              </Routes>
            </Fragment>
          </div>
          {/* <FooterSection />
          <FlowerOne />
          <FlowerTwo />
          <FlowerThree /> */}
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
