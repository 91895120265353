import { Typography } from '@mui/material';
import React from 'react';
import { imageUrl } from '../../utils';

// Main component
const MediaCard = ({ title, description, iconSrc }: any) => {
    return (
        <div className="flex ">
            <div className="bg-[#F4f4F4] h-[60px] min-w-[60px] rounded-full flex justify-center items-center">
                <img src={imageUrl(iconSrc)} alt="icon" className="p-2 w-12" />
            </div>
            <div className="ml-4">
                <Typography style={styles.heading}>
                    {title}
                </Typography>
                <Typography style={styles.paragraph}>
                    {description}
                </Typography>
            </div>
        </div>
    );
};

// Styles
const styles = {
    heading: {
        fontSize: 'clamp(14px, 20px, 24px)',
        color: '#263238',
        fontWeight: 500,
        fontFamily: 'Plus Jakarta Sans, sans-serif',
    },
    paragraph: {
        fontSize: 14,
        color: '#728F8B',
        fontWeight: 500,
        fontFamily: 'ABeeZee, sans-serif',
    },
};

export default MediaCard;
