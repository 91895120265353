import { useState } from 'react';
import SquareButton from '../buttons/SquareButton';
import SubInfo from './SubInfo';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import classNames from 'classnames';
import { motion, MotionConfig } from 'framer-motion';
import useMeasure from 'react-use-measure';
import { useMediaQuery } from '@mui/material';


const QuestionAnswer = ({ question, answer }: { question: string; answer: string }) => {
  const [expanded, setExpanded] = useState(false);
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const qaFontSize = {fontSize: isLessThan640 ? '14px': '16px'};

  const questionContainerClasses = classNames(
    'cursor-pointer bg-orange w-11/12 sm:w-3/4 rounded-lg border-[#263238]',
    {
      'bg-orange': expanded,
      'bg-question-bg': !expanded,
      'border-[1px]': expanded,
    },
    isLessThan640 ? 'px-3 my-2' : 'px-6 my-3'
  );
  const questionClasses = isLessThan640
    ? classNames('flex justify-between items-center px-0 py-3 gap-1')
    : classNames('flex justify-between items-center py-5 gap-5');
  const answerClasses = isLessThan640 ? classNames('pb-5 pr-15') : classNames('pb-5 pr-20');
  const [ref, { height }] = useMeasure();

  return (
    <MotionConfig transition={{ duration: 0.3 }}>
      <motion.div
        className={questionContainerClasses}
        animate={{
          height: height,
        }}
        onClick={() => {
          setExpanded((current) => !current);
        }}>
        <div ref={ref}>
          <div className={questionClasses}>
            <div
              className={classNames('border-[#263238] flex-1', {
                'border-b-[1.5px]': expanded,
                'pb-5': expanded,
              })}>
              <SubInfo typographyStyles={{ color: expanded ? '#263238' : '#728F8B', ...qaFontSize }}>{question}</SubInfo>
            </div>
            <SquareButton>
              {expanded ? (
                <RemoveIcon style={{ color: 'white', fontSize: 15 }} />
              ) : (
                <AddIcon style={{ color: 'white', fontSize: 15 }} />
              )}
            </SquareButton>
          </div>

          {expanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className={answerClasses}
              >
              <SubInfo typographyStyles={{ color: '#263238', ...qaFontSize }}>{answer}</SubInfo>
            </motion.div>
          )}
        </div>
      </motion.div>
    </MotionConfig>
  );
};

export default QuestionAnswer;
