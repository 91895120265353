import { RoundedArrowButton } from '../../components/buttons';
import WithNavbarHeightApplied from '../../components/hoc/WithNavbarHeightApplied';
import GapAround from '../../components/hoc/GapAround';
import BigColumn from '../../components/layout/BigColumn';
import { imageUrl } from '../../utils';
import SubInfo from '../../components/common/SubInfo';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import { useTranslation } from 'react-i18next';
import { useMediaQueries } from '../../contexts/media-query';
import { FlowerFour, FlowerOne } from './Flowers';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = ({pillLabel,title,description,image,renderButton,buttonText}:any) => {
  const { t } = useTranslation();
  const { bigColumnClass, isLessThan1280, isLessThan640 } = useMediaQueries();
  const imgSrc = imageUrl(image);
  const isLessThan899 = useMediaQuery('(max-width: 899px)');
  const navigate = useNavigate();
  const isLessThan1036 = useMediaQuery('(max-width: 1036px)');

  return (
    <div>
      <FlowerOne/>
      <FlowerFour/>
      <WithNavbarHeightApplied>
        <GapAround spaceTaken={2}>
          <BigColumn containerStyle={bigColumnClass} childStyle={{ alignItems: 'center', paddingBottom: isLessThan1036 ? '10px' : '3rem' }}>
            {/* Left information section start */}
            <div style={isLessThan899 ? {backgroundImage: `url(${imageUrl(image?.split('.')[0] + '-o.svg')})`} : {}} className="breaktwo:pb-5 bg-no-repeat bg-top header-image breaktwo:flex breaktwo:flex-col justify-center items-center breaktwo:text-center before:bg-top before:absolute before:h-full before:-z-10')] before:opacity-10">
              <RoundInfo containerClasses="breaktwo:mb-3 mb-6 breaktwo:mt-6">
                {t(pillLabel||'')}
              </RoundInfo>
              <Info
                typographyStyles={{
                  fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
                }}
              >
                {t(title||'')}
              </Info>
              <SubInfo
                containerClasses="w-[80%] my-4"
                typographyStyles={{ fontFamily: 'ABeeZee, sans-serif' }}
              >
                {t(description||'')}
              </SubInfo>
            {renderButton &&  <div className="breaktwo:mt-5 mt-10">
                <RoundedArrowButton
                  movingArrow
                  text={t(buttonText)}
                  containerClasses="bg-yellow w-72"
                  onClick={() => navigate('/contact-us')}
                />
              </div>}
            </div>
            {/* Left information section end */}
            {/* Right image section start */}
            <div className="w-full h-full flex items-center -z-10 justify-end breaktwo:hidden mt-5" style = {{minHeight:'500px'}}>
              <img alt="" className="h-full w-[500px]" src={imgSrc} />
            </div>
            {/* Right image section end */}
          </BigColumn>
        </GapAround>
      </WithNavbarHeightApplied>
    </div>
  );
};

export default Header;
