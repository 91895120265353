import React from 'react';
import MediaCard from '../../components/common/MediaCard'; // Adjust the import path as necessary
import GapAround from '../../components/hoc/GapAround';
import { useMediaQueries } from '../../contexts/media-query';
import classNames from 'classnames';


const ServiceList = ({ items }: any) => {
    const { isLessThan1150} = useMediaQueries()
    return (
        <div className='mb-20 breaktwo:mb-14 mdmax:px-5'>
            <GapAround>
                <div className={classNames(isLessThan1150 ? 'mt-0 px-6 smmax:px-0' : 'mt-14', 'border-t border-[#E7E7E7] pt-14 grid grid-cols-2 gap-6 smmax:gap-4 breakone:flex breakone:flex-col breaktwo:mt-8 breaktwo:pt-8')}>
                    {items.map((item: any, index: any) => (
                        <MediaCard
                            key={index}
                            title={item.title}
                            description={item.description}
                            iconSrc={item.iconSrc}
                        />
                    ))}
                </div>
            </GapAround>
        </div>
    );
};

export default ServiceList;