import { TwContainer } from '../../typings/tw.types';
import SubInfo from './SubInfo';

const LiIcon = (
  <div className="w-5 h-5 flex justify-center items-center bg-orange rounded-full border-[#263238] border-[.1px]">
    <div className="w-3 h-3 rounded-full bg-[#263238]"></div>
  </div>
);

const CustomLi = ({ children }: TwContainer) => {
  return (
    <div className="flex items-start bg-red gap-3 my-3 breaktwomin:my-6">
      {LiIcon}
      <SubInfo containerClasses="w-[90%] -mt-[1.75px]">{children}</SubInfo>
    </div>
  );
};

export default CustomLi;
