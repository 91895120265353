import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
import { imageUrl } from '../../utils';
import SubInfo from './SubInfo';
import { useMediaQuery } from '@mui/material';

interface ClientCardProps extends TwContainer {
  name: string;
  icon: string;
  description: string;
  role: string;
}

const ClientCard = ({ name, icon, description, containerClasses, role }: ClientCardProps) => {
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  return (
    <div
      className={classNames(
        'shadow-md rounded-[5px] flex flex-col mx-0 md:mx-5 p-5 min-h-[450px] lg:p-8',
        containerClasses,
      )}>
      <div className="flex items-center mb-5 ml-3">
        <img
          className={`glowing-circle rounded-full border-4 border-light-blue object-contain ${
            isLessThan1280 ? (isLessThan640 ? 'w-12 h-12 p-2' : 'w-24 h-24 p-2') : 'w-28 h-28 p-2'
          }`}
          src={imageUrl(icon)}
          alt=""></img>
        <div className="ml-5">
          <SubInfo
            typographyStyles={{
              fontSize: isLessThan1280 ? 17 : 20,
              color: '#263238',
              fontWeight: 500,
              fontFamily: 'Plus Jakarta Sans, sans-serif',
            }}>
            {name}
          </SubInfo>
          <div className="text-sm italic">{role}</div>
        </div>
      </div>
      <SubInfo typographyStyles={{ fontSize: isLessThan1280 ? 16 : 18, lineHeight: 1 }}>"{description}"</SubInfo>
    </div>
  );
};

export default ClientCard;
