import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface Obj {
  url: string;
  data?: any;
  headers?: any;
  tag?:string;
  page?:number;
  count?:number;
  viewMore?:boolean;
}

export const getApi = (obj: Obj) => {
  return axios.get(API_BASE_URL + obj.url, {
    params: {
      ...obj.data,
    },
  })
}

export const postApi = (obj: Obj) => {
  return axios.post(
    API_BASE_URL + obj.url,
    {
      ...obj.data,
    },
  );
}

export const storyBlockApi = (obj:Obj) => {
  const version = 'draft'
  const startWith = obj.url
  const tag = obj.tag
  const page = obj.page || 1
  const count = obj.count || 4
  const viewMore = obj.viewMore
  let url = `/stories?version=${version}&token=${process.env.REACT_APP_STORYBLOK_API_KEY}`
  if(!viewMore) {
    url += `&starts_with=${startWith}&per_page=${4}&page=${page}`
  }else{
    url +=  url += `&starts_with=${startWith}&per_page=${4 * page}&page=${1}`
  }
  if(tag && tag !== 'All'){
    url+=`&with_tag=${tag}`
  }
  return axios.get(
    process.env.REACT_APP_STORYBLOK_BASE_URL + url
  );
}