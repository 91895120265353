import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ComponentDivProps } from '../../typings/html.types';
import FooterSection from '../../views/landing/FooterSection';
import Navbar from '../layout/Navbar';

const WithNavbarAndFooter = ({ children }: ComponentDivProps) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Navbar/>
      <div style={{marginTop:'8rem'}}>{children}</div>
      <FooterSection/>
    </div>
  );
};

export default WithNavbarAndFooter;
