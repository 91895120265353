import { imageUrl } from '../../utils'
import { Typography } from '@mui/material'

interface Props { label: string }

const SoftwareItem = (props: Props) => {
    const { label } = props

    return (
        <div className = 'grid-item'>
        <div className='flex gap-3 items-center'>
            <img src={imageUrl('software_item_dot.svg')} />
            <Typography
                style={
                    {
                        fontFamily: 'ABeeZee, sans-serif',
                        fontSize: '16px',
                        textAlign:'left'
                    }
                }
            >
                {label}
            </Typography>

        </div>
        </div>
    )
}

export default SoftwareItem