import { FlowerOne, FlowerThree, FlowerTwo } from '../components/common/Flowers';
import Header from '../components/common/Header';
import ContactUs from '../views/landing/ContactUs';
import FooterSection from '../views/landing/FooterSection';
import StepsWeFollow from '../views/landing/StepsWeFollow';
import WhyUs from '../views/landing/WhyUs';
import TrustedBySection from '../views/landing/TrustedBySection'

const LandingPage = () => {
  return (
    <>
      <Header
        pillLabel='our_team_your_team'
        title='motto'
        description='who_are_we'
        image='ladder-to-cup.svg'
        renderButton
        buttonText='get_instant_quote'
      />
      <TrustedBySection />
      <WhyUs />
      <StepsWeFollow />
      <ContactUs />
    </>
  );
};

export default LandingPage;