import { createTheme } from '@mui/material';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subinformation: React.CSSProperties;
    cardheading: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subinformation?: React.CSSProperties;
    cardheading?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subinformation: true;
    cardheading: true;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    button: {
      fontFamily: 'ABeeZee, Plus Jakarta Sans, sans-serif',
      fontWeight: 400,
      textTransform: 'capitalize',
      fontSize: 17,
    },
    subtitle1: {
      fontFamily: 'Plus Jakarta Sans, sans-serif',
      fontWeight: 700,
      fontSize: 22,
    },
    subtitle2: {
      fontFamily: 'Plus Jakarta Sans, sans-serif',
      fontWeight: 500,
      fontSize: 18,
    },
    subinformation: {
      fontFamily: 'Plus Jakarta Sans, sans-serif',
      lineHeight: 1.5,
      color: '#728F8B',
      fontSize: 17,
    },
    cardheading: {
      fontFamily: 'ABeeZee, sans-serif',
      fontSize: 68,
      fontStyle: 'italic',
    },
    h1: {
      fontSize: 42,
      fontWeight: 400,
    },
  },
});
