import React from 'react'
import Header from '../components/common/Header'
import Banner from '../components/common/Banner'
import ServiceList from '../views/dev/ServiceList'
import { software_serviceItems } from '../constants/servicesItems'
import SoftwareServiceBanner from '../views/services/softwareConsult/SoftwareServiceBanner'
  
interface Props { }

const SoftwareConsulting = (props: Props) => {
    const { } = props

    return (
        <>
            <Header
                pillLabel='software_consulting'
                title='software_consulting_motto'
                description='software_consulting_description'
                renderButton
                buttonText='get_instant_quote'
                image='software.svg'
            />
            <Banner
                pillLabel='software_consulting_banner_pill'
                title='software_consulting_banner_title'
                description="software_consulting_banner_description"
            />
            <ServiceList
                items={software_serviceItems}
            />
            <SoftwareServiceBanner
            pillLabel={'software_consulting'}
            />
        </>
    )
}

export default SoftwareConsulting