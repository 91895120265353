import React from 'react'
import Header from '../components/common/Header'
import ContactUs from '../views/landing/ContactUs'

interface Props {}

const ContactUsPage = (props: Props) => {
    const {} = props

    return (
        <>
        <Header
        pillLabel='contact_us'
        title='content_us_motto'
        description='contact_us_description'
        image = 'contact_us.svg'
        renderButton={false}
        />
        <ContactUs/>
        </>
    )
}

export default ContactUsPage