import React, { createContext, useContext } from 'react';
import { useMediaQuery } from '@mui/material';

// Creating a context object
const MediaQueryContext = createContext(null);

// Provider component
export const MediaQueryProvider = ({ children }:React.PropsWithChildren) => {
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const isLargeThan899 = useMediaQuery('(min-width: 899px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan1024 = useMediaQuery('(max-width: 1024px)');
  const isLessThan899 = useMediaQuery('(max-width: 899px)');

  const bigColumnClass = isLessThan1150 && isLargeThan899 ? {
    paddingLeft: '60px', 
    paddingRight: '50px', 
    marginBottom: '50px'
  } : undefined;

  const contextValue:any = {
    isLessThan640,
    isLessThan1150,
    isLargeThan899,
    isLessThan1280,
    bigColumnClass,
    isLessThan1024,
    isLessThan899,
  };

  return (
    <MediaQueryContext.Provider value={contextValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// Custom hook to use the context
export const useMediaQueries = () => {
  const context = useContext(MediaQueryContext);
  if (context === null) {
    throw new Error('useMediaQueries must be used within a MediaQueryProvider');
  }
  return context;
};