import React from 'react'
import TrustedBySection from '../views/landing/TrustedBySection'
import OurExpertise from '../views/landing/OurExpertise'
import ServicesSection from '../views/landing/ServicesSection'
import FaqSection from '../views/landing/FaqSection'
import { expertiseArray } from '../constants/Expertise'
import MeetUs from '../views/team/MeetUs'
import OurTeam from '../views/team/OurTeam'
import { useMediaQuery } from '@mui/material'
import classNames from 'classnames'

interface Props { }

const About = () => {
    const isLessThan1331 = useMediaQuery('(max-width: 1331px)');

    return (
        <div className="mt-48 mdmax:mt-36">
            <MeetUs />
            <div className={classNames('breaktwo:mb-12 mb-20 mdmax:mt-16', isLessThan1331 ? 'mt-24':'mt-44')}>
                <OurExpertise 
                    title = 'our_expertise'
                    description = 'most_up_to_date_tech'
                    displayItems = {expertiseArray}
                />
            </div>
            <ServicesSection />
            <FaqSection />
        </div>
    )
}

export default About