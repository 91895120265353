export const imageUrl = (file: string) => {
  return `${process.env.REACT_APP_ASSETS}${file}?alt=media`;
};

export const linkOffsets: number[] = [0, -100, -100, -100, -110, -120];



export function formatDate(dateStr:any) {
  console.log(dateStr)
  const date = new Date(dateStr);
  const options:any = { year: 'numeric', month: 'long', day: '2-digit' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}