import { Typography } from '@mui/material';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { TwContainer } from '../../typings/tw.types';
import { imageUrl } from '../../utils';
interface ResultsBoxProps extends TwContainer {
  heading: string;
  subheading: string;
  describeImage: string;
  headingStyle?: CSSProperties;
  subHeadingStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  noWidth?: boolean;
}

const ResultBox = ({
  containerClasses,
  heading,
  subheading,
  describeImage,
  headingStyle,
  subHeadingStyle,
  iconStyle,
  noWidth,
}: ResultsBoxProps) => {
  return (
    <div
      className={classNames('rounded-xl shadow-sm', {
        'w-full sm:w-1/2 lg:w-1/3': !noWidth,
      }, containerClasses)}>
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="cardheading" style={headingStyle}>
            {heading}
          </Typography>
        </div>

        <div style={iconStyle}>
          <img src={imageUrl(describeImage)} alt="" />
        </div>
      </div>
      <div>
        <Typography style={{ fontFamily: 'ABeeZee, sans-serif', ...subHeadingStyle }}>{subheading}</Typography>
      </div>
    </div>
  );
};

export default ResultBox;
