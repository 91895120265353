interface ErrorTextProps {
  text: string;
}

const ErrorText = ({ text }: ErrorTextProps) => {
  return (
    <div className="text-red-500 text-sm mt-1">
      {text}
    </div>
  );
};

export default ErrorText;
