import { v4 } from 'uuid';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';
import SubInfo from './SubInfo';
import { CSSProperties, useState } from 'react';
import { TickSquareCheckedIcon, TickSquareIcon } from '../../icons/';

interface checkbox extends TwContainer {
  label?: string;
  checkboxStyle?: CSSProperties;
}

function Checkbox({ label, containerClasses, checkboxStyle }: checkbox) {
  const id = v4();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className={classNames('flex items-center gap-2.5', containerClasses)}>
      <MuiCheckbox
        onChange={(e) => setIsChecked(e.target.checked)}
        id={id}
        className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-main"
        style={{ color: isChecked ? '#FCAE4C' : '#DDDDDD', ...checkboxStyle }}
        sx={{
          '& .MuiSvgIcon-root': {
            borderRadius: 200,
          },
        }}
        icon={<TickSquareIcon style={{ fontSize: 18 }} />}
        checkedIcon={<TickSquareCheckedIcon style={{ fontSize: 18 }} />}
      />
      <label htmlFor={id} className="cursor-pointer">
        <SubInfo typographyStyles={{ fontSize: 16 }}>{label}</SubInfo>
      </label>
    </div>
  );
}

export default Checkbox;
