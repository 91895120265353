import { CSSProperties } from 'react';

import { Grid, useMediaQuery } from '@mui/material';

import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';

interface GapAroundProps extends TwContainer {
  spaceTaken?: number;
  containerStyle?: CSSProperties;
}

const GapAround = ({ children, spaceTaken = 0, containerStyle, childrenClasses }: GapAroundProps) => {
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const isLessThan2440 = useMediaQuery('(max-width: 2440px)');
  const isLessThan1440 = useMediaQuery('(max-width: 1440px)');

  return (
    <div className="flex justify-center items-center relative h-full" style={{ ...containerStyle }}>
        <div className={classNames(isLessThan1150 ? 'mx-0' : (isLessThan1440 ? 'mx-24' : 'mx-32'), isLessThan2440 ? 'max-w-[1100px]' : 'max-w-[1320px]', childrenClasses)}>{children}</div>
    </div>
  );
};

export default GapAround;
